import 'babel-polyfill'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import vuetify from '@plugins/vuetify'
import { application, bootstrap } from '@baxter/web-vueapp-lib'
import pluginInstaller from '@plugins/pluginInstaller'
import AppToolbarLogo from './plugins/assets/images/AppToolbarLogoBase64'

bootstrap(Vue, [])
  .then(({ store, router, i18n }) => {
    pluginInstaller({ store: store, router: router, i18n: i18n })

    /** Create the Vue Instance and setup the router, store, etc.. */
    new Vue({
      vuetify,
      router,
      store,
      i18n,
      beforeCreate() {
        this.$store.commit('initialiseStore')
        this.$appStoreHelper.setCustomOptions({
          toolbarLogo: {
            image: AppToolbarLogo.img,
            style: { width: '130px', 'margin-right': '10px', height: '30px' },
          },
        })
      },
      render: (h) => h(application),
    }).$mount('#app')
  })
  .catch((error) => {
    console.log('Bootstraping vuejs app failed')
    console.log(error)
  })
